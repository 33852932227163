<template>
  <div>
    <v-data-table
      :headers="transactions_headers"
      :items="transactions"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-0"
    >
      <template v-slot:item.active_status="{ item }">
        <v-chip
          :color="
            item.status == 'Pending'
              ? 'warning'
              : item.status == 'Approved'
              ? 'green'
              : 'red'
          "
          dark
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <p
          class="mb-0 blue--text text-decoration-underline"
          style="cursor: pointer"
          @click="onViewReceipt(item)"
        >
          View Receipt
        </p>
      </template>
      <template v-slot:item.custom_date="{ item }">
        <p v-if="item.created_date">
          {{ new Date(item.created_date).toLocaleString() }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["render"],
  data() {
    return {
      loading: true,
      total_payable: "",
      total_receivableable: "",
      user: "",
      transactions_headers: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "custom_date",
          class: "custom-header black--text",
          divider: true,
        },
         {
          text: "Order ID",

          sortable: false,
          value: "order_id",
          class: "custom-header black--text",
          divider: true,
        },
         {
          text: "From",

          sortable: false,
          value: "transaction_from",
          class: "custom-header black--text",
          divider: true,
        },
         {
          text: "To",

          sortable: false,
          value: "transaction_to",
          class: "custom-header black--text",
          divider: true,
        },
        {
          text: "Transtaction Type",
          value: "transaction_type",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Debit",
          value: "debit",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Credit",
          value: "credit",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Balance",
          value: "balance_sheet",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        // {
        //   text: "Recieveable",
        //   value: "total_admin_receiveable",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        //   align: "center",
        // },

        // {
        //   text: "Merchant Payable",
        //   value: "total_seller_payable",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        // {
        //   text: "Deposited Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        // {
        //   text: "Merchant Receiveable",
        //   value: "total_seller_receiveable",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
      ],
      transactions: [],
    };
  },

  watch: {
    render: function () {
      // If new transaction added then load the transaction list again
      this.getAllTransactions();
    },
  },

  methods: {
    // Get all transaction list
    getAllTransactions() {
      this.loading = true;
      axios
        .get(`merchant/all_balance_report/${this.user.user_id}/`)
        .then((response) => {
          if (response.data.success && response.data.data) {
            this.transactions = response.data.data;
          } else {
            this.transactions = [];
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // View receipt
    onViewReceipt(item) {
      // console.log({ item });
    },
  },

  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getAllTransactions();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>
